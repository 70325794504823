<template>
  <b10-base>
    <ValidationProvider
      v-if="isInsert"
      v-slot="{ errors }"
      name="tdato_sistema"
      :rules="formRules.tdato_sistema"
    >
      <b10-autocomplete
        v-model="form.tdato_sistema"
        :items="tipoDatoSistema"
        item-text="descripcion"
        label="Tipo de dato"
        clearable
        return-object
        :error-messages="errors"
        @change="tdatoSistemaSelected"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="valor"
      :rules="formRules.valor"
    >
      <div
        v-show="mostrarCampoValor"
      >
        <v-text-field
          v-if="TDATO_TDATO_SISTEMA.tiposString.includes(clasificacionTipoDato)"
          v-model="form.valor"
          clearable
          flat
          label="Valor"
          :error-messages="errors"
        />
        <v-text-field
          v-else-if="TDATO_TDATO_SISTEMA.tiposNumber.includes(clasificacionTipoDato)"
          v-model.number="form.valor"
          type="number"
          clearable
          flat
          label="Valor"
          :error-messages="errors"
        />
        <b10-date-picker
          v-else-if="TDATO_TDATO_SISTEMA.tiposDate.includes(clasificacionTipoDato)"
          v-model="form.valor"
          title="Valor"
          :error-messages="errors"
        />
        <v-select
          v-else-if="TDATO_TDATO_SISTEMA.tiposBoolean.includes(clasificacionTipoDato)"
          v-model="form.valor"
          :items="TDATO_TDATO_SISTEMA.selectBoolean"
          title="Valor"
          :error-messages="errors"
        />
      </div>
    </ValidationProvider>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './DatoSistemaFormData'
import { TDATO_TDATO_SISTEMA } from '@/utils/consts'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {
    idsistema: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      form: {
        tdato_sistema: null,
        valor: null,
        tipo: null
      },
      formRules: {
        tdato_sistema: { required_if: { target: this.isInsert, value: true } },
      },
      tipoDatoSistema: [],
      clasificacionTipoDato: null,
      TDATO_TDATO_SISTEMA,
      mostrarCampoValor: false,
    }
  },
  async created () {
    const resp = await Data.selectLookups(this, this.idsistema)
    this.tipoDatoSistema = resp.data.selectTdatoSistema.result.dataset
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.form = formData
      formData.tdato_sistema = _.find(this.tipoDatoSistema, { 'idtdato_sistema': formData.idtdato_sistema })
      if (formData.tdato_sistema) {
        this.clasificacionTipoDato = formData.tipo
        this.form.tdato_sistema = await Data.selectTdatoSistema(this, formData.idtdato_sistema)
        this.mostrarCampoValor = true
      }
    },
    async tdatoSistemaSelected (value) {
      if (value) {
        this.$set(this.form, 'valor', null)
        this.$set(this.form, 'tipo', value.tipo)
        this.clasificacionTipoDato = value.tipo
        this.$set(this.formRules, 'valor', { required: !value.opcional })
        this.mostrarCampoValor = true
      }
    },
  },
}
</script>
